<template>
  <div>
    <van-cell class="cell">
      <div class="title">1.{{single.content}} ({{single.typeStr}})</div>
      <div id="cake">
        <div ref="echarts" class="chart-box"></div>
      </div>
    </van-cell>
    <van-cell class="cell">
      <div class="title">2.{{multiple.content}} ({{multiple.typeStr}})</div>
      <div id="column">
        <div ref="column" class="chart-box"></div>
      </div>
    </van-cell>
    <van-cell class="cell">
      <div class="title">3.{{brief.content}} ({{brief.typeStr}})</div>
      <van-button @click="checkHandle">查看详细信息</van-button>
    </van-cell>
    <answer-info v-if="answerInfoVisible" ref="answerInfo"></answer-info>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import answerInfo from './answer-info'
import * as echarts from 'echarts'
export default {
  components:{
    answerInfo
  },
  data () {
    return {
      single: {
        content: '',
        typeStr: '',
        nameList: []
      },
      singleList:[],
      multipleList:[],
      multiple: {
        content: '',
        typeStr: '',
        nameList: []
      },
      brief: {
        content: '',
        typeStr: ''
      },
      id: 0,
      chartLine: null,
      columnLine: null,
      answerInfoVisible: false
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getInfo()
  },
  mounted() {
    this.initChart()
    this.initColumn()
  },
  activated () {
    // 由于给echart添加了resize事件, 在组件激活时需要重新resize绘画一次, 否则出现空白bug
    if (this.chartBar) {
      this.chartBar.resize()
    }
  },
  methods: {
    checkHandle() {
      this.answerInfoVisible = true
      let id = this.id
      this.$nextTick(() => {
        this.$refs.answerInfo.init(id)
      })
    },
    getInfo() {
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/questionnaire/statistics'),
        method: 'post',
        params: this.$httpApp.adornParams({
          id: this.id,
          orgId: this.$orgId
        },false)
      }).then(({data}) => {
        if (data.code == 0) {
          data.list.map(item => {
            if (item.type === '1') {
              this.single.content = item.content
              this.single.typeStr = item.typeStr
            } else if (item.type === '2') {
              this.multiple.content = item.content
              this.multiple.typeStr = item.typeStr
            } else if (item.type === '0') {
              this.brief.content = item.content
              this.brief.typeStr = item.typeStr
            }
          })
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    resize(val) {
      let nowClientWidth = document.documentElement.clientWidth
      return val * (nowClientWidth / 1920)
    },
    initChart() {
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/questionnaire/statistics'),
        method: 'post',
        params: this.$httpApp.adornParams({
          id: this.id,
          orgId: this.$orgId
        },false)
      }).then(({data}) => {
        if (data.code == 0) {
          data.list.map(item => {
            if (item.type === '1') {
              item.statistics.map(items => {
                this.single.nameList.push(items.name)
                this.singleList.push({name: items.name, value: items.value})
              })
            }
          })
          var option = {
            tooltip: {
              trigger: 'item',
              formatter: '{b}: {c} ({d}%)'
            },
            legend: {
              icon: 'rect',
              itemWidth: this.resize(50),
              itemHeight: this.resize(50),
              itemGap: this.resize(120),
              bottom: 'bottom',
              itemStyle: {
                borderCap: 'square'
              }
            },
            color: ['#3780f4', '#ff7b45', '#ffc63c', '#36d1c9'],
            series: [
              {
                name: this.single.nameList,
                type: 'pie',
                radius: '50%',
                data: this.singleList,
                label: {
                  // normal: {
                  //   show: true,
                  //   formatter: (e) => {
                  //     let value
                  //     this.singleList.filter(item => {
                  //       if (item.name === e.name) {
                  //         value = item.value
                  //       }
                  //     })
                  //     return (value / 100) + '%'
                  //   }
                  // }
                  // normal:{
                  label:{
                    show: true,
                    formatter: '{b}:{c}({d}%)'
                  },
                  labelLine :{show:true},
                  // }
                },
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                },
                labelLine: { // 数据引导线
                  show: true,
                  length: this.resize(80),
                  length2: this.resize(40),
                  smooth: true
                },
              }
            ]
          }
          this.chartLine = echarts.init(this.$refs.echarts)
          this.chartLine.setOption(option)
          window.addEventListener('resize', () => {
            if (this.chartBar) {
              this.chartBar.resize()
            }
          })
        } else {
          this.$toast.fail(data.msg);
        }
      })

    },
    initColumn() {
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/questionnaire/statistics'),
        method: 'post',
        params: this.$httpApp.adornParams({
          id: this.id,
          orgId: this.$orgId
        },false)
      }).then(({data}) => {
        if (data.code == 0) {
          data.list.map(item => {
            if (item.type === '2') {
              item.statistics.map(items => {
                this.multiple.nameList = items.xdata
                this.multipleList = items.ydata
              })
            }
          })
          var options = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
              formatter: '{b}:{c}'
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: {
              type: 'value',
              boundaryGap: [0, 0.1],
              min: 0,
              max: 40,
              splitNumber: 5,
              axisLine: {
                show: true,
                lineStyle: {
                  color: ['#c1d0e0']
                }
              },
              axisLabel: {
                textStyle: {
                  color: '#000'
                }
              }
            },
            yAxis: {
              type: 'category',
              splitNumber: 5,
              data: this.multipleList,
              axisLine: {
                show: true,
                lineStyle: {
                  color: ['#c1d0e0']
                }
              },
              axisLabel: {
                textStyle: {
                  color: '#000'
                }
              }
            },
            series: [
              {
                name: this.multipleList,
                type: 'bar',
                data: this.multiple.nameList,
                itemStyle: {
                  normal: {
                    color: function (params) {
                      var colorList = ['#ff7b45', '#ffc63c', '#36d1c9', '#3780f4'];
                      return colorList[params.dataIndex]
                    },
                    label: {
                      show: true,  //开启显示
                      formatter: '{c}',
                      position: 'right',
                      textStyle: {  //数值样式
                        color: 'black',
                        fontSize: this.resize(60)
                      },
                    }
                  }
                }
              },
            ]
          }
          this.columnLine = echarts.init(this.$refs.column)
          this.columnLine.setOption(options)
          window.addEventListener('resize', () => {
            if (this.chartBar) {
              this.chartBar.resize()
            }
          })
        } else {
          this.$toast.fail(data.msg);
        }
      })
    }
  }
}
</script>
<!--<style>-->
<!--.notice .van-field__control{-->
<!--  border: 2px solid #eee;-->
<!--  border-radius: 10px;-->
<!--  padding-left: 15px;-->
<!--  padding-top: 5px;-->
<!--}-->
<!--</style>-->
<style lang="scss" scoped>
#cake {
  position: relative;
  width: 750px;
  height: 500px;

  .chart-box {
    height: 100%;
    z-index: 999;
    margin-right: 50px;
  }
}
#column {
  position: relative;
  width: 750px;
  height: 500px;
  .chart-box {
    height: 100%;
    z-index: 999;
    margin-right: 50px;
  }
}
.cell {
  .van-button {
    height: 70px;
    color: #367ff3;
    border: 1px solid #397ff3;
    background-color: #ebf2fd;
    border-radius: 10px;
    margin-top: 20px;
  }
}
</style>
